import React, { useState } from 'react'
import tw, { css } from 'twin.macro'

import Footer from '../components/Footer'
import Header from '../components/Header'
import Layout from '../components/Layout'
import Menu from '../components/Menu'

import { StyledSection, h1Styles, legalPoliciesParagraph } from '../styles'

const innerSectionStyles = css`
  ${tw`flex flex-col items-center min-h-screen py-40`}

  h2 {
    font-weight: 400;
    margin: 1rem 0 0.5rem 0;
  }

  p {
    ${legalPoliciesParagraph};
  }

  li {
    margin-left: 1.5rem;
    list-style: auto;

    &:not(:last-child) {
      margin-bottom: 0.25rem;
    }
  }
`

const LegalNotice = ({ location }) => {
  const [showFullMenu, setShowFullMenu] = useState(false)

  const handleMenuAction = () => {
    setShowFullMenu((menuState) => !menuState)
  }

  return (
    <Layout title="Legal Notice" description="Legal Notice">
      <Header menuAction={handleMenuAction} />
      <main tw="relative">
        <section css={innerSectionStyles}>
          <StyledSection>
            <h1 css={h1Styles}>Legal Notice</h1>
            <div css={legalPoliciesParagraph}>
              <h2>1. WEBSITE OWNERSHIP</h2>
              <p>
                SAMY ROAD, SL (hereinafter, the Entity) is the holder of this web page (hereinafter,
                the Website). Its registered office is at C. de Ulises, 97, 28043, Madrid and its
                tax number is B86442852.
              </p>
              <p>You may contact us through the following means:</p>
              <p>Telf.: 915048585</p>
              <p>Email: legal@samyroad.com</p>
              <p>
                This Website will not be used in connection with other contents, products and/or
                services which are not owned by the Entity and/or its affiliates and/or branch
                offices.
              </p>
              <p>
                For the purposes of this Legal Notice, Users are those persons accessing the Website
                and/or sending a request or email to the Website or completing a Website form or
                registration process.
              </p>

              <h2>2. SCOPE</h2>
              <p>
                This Legal Notice contains all the terms and conditions that regulate: a) the
                access, navigation and use of the Website; b) the responsibilities arising from use
                of the Website and the provision of and/or contracting of products or services which
                may be offered through the Website; and c) the provision and use of Website content.
                Notwithstanding anything herein, the foregoing is without prejudice to the fact that
                the Entity may establish specific case-by-case conditions which regulate the use,
                provision and/or contracting of products or services which are offered to Users
                through the Website. In any case, those specific conditions will form an integral
                part of this Legal Notice.
              </p>
              <p>
                Performance by the User of any single act among the following will constitute
                acceptance without reservation of each and every one of the rules found in this
                Legal Notice and will be taken as consideration on the part of the User: a)
                accessing the Website, b) filling out forms through the Website, c) sending requests
                for information or complaints, d) accepting employment/contractual offers or
                subscriptions, and e) in general, all acts of a similar nature to those carried out
                when filling out forms and/or when contacting via email addresses published on the
                Website. You must therefore read and understand the content of this Legal Notice.
              </p>
              <p>
                Should the use, provision and/or contracting of products or services be offered
                through the Website, the mere fact of being used and/or requested by the User will
                constitute, equally, the User´s acceptance without reservation of the corresponding
                established specific conditions which will also form an integral part of this Legal
                Notice.
              </p>

              <h2>3. WEBSITE USE AND ACCESS</h2>
              <p>
                Access to the Website by the Users is free. However, the use, provision and/or
                contracting of the products and services which may be offered by the Entity may be
                subject to the previous acceptance of formal requisites such as the filling out of
                corresponding forms, payment of fees and costs and/or the previous acceptance of
                specific conditions which apply to the same.
              </p>
              <p>
                The website is aimed at being used by adults (+18) and is not intended for use by
                children. Children under 18 years old are not allowed to access to the Website
                and/or services.
              </p>
              <p>
                Merely accessing the Website does not imply the establishment of any link or
                commercial relationship between the Entity and the User, except where the
                appropriate means have been established and the User has previously complied with
                the requisites which are established.
              </p>
              <p>
                Information on the Website relating to products or services offered by the Entity is
                solely for information and advertising purposes, unless otherwise stated.
              </p>
              <p>
                If for the use, provision and/or contracting of a product or service offered through
                the Website, the User is obliged to register, he/she will be under an obligation to
                provide accurate information, guaranteeing the authenticity of all the data provided
                at the time of filling out the pre-established forms required to access the
                corresponding products or services. If, as a result of the User’s registration, a
                password is issued, the User thereby is bound to use it diligently and to keep such
                password secret. Consequently, Users will be responsible for the adequate custody
                and confidentiality of all identifying data and/or passwords and are bound to not
                allow or facilitate their use by third parties, be it temporarily or permanently,
                nor to provide access to others. The use and/or contracting of products or services
                by third parties acquired due to a fault or negligent use or misuse of a password
                given to the User and/or the loss of the password by the User will be entirely the
                responsibility of the User.
              </p>
              <p>
                Furthermore, it is the User’s duty to immediately notify the Entity of any
                circumstances which may lead to the improper use of identifying data and/or
                passwords, such as theft, loss or non-authorized access, so that the Entity can
                proceed with prompt cancellation. Without limitation to any other provision hereof,
                for the duration of any such period during which any such circumstances are not
                communicated to the Entity, the Entity will be exempt from any responsibility which
                could derive from the improper use of the identifying data or use or misuse of
                passwords by third parties.
              </p>
              <p>
                In all cases, the access, navigation and use of the Website, and the use or
                contracting of the services or products offered through the Website, is the sole and
                exclusive responsibility of the User. The User is therefore bound to diligently and
                faithfully observe any additional instructions given by the Entity or by the
                Entity’s authorized employees in relation to the Website’s use and its contents.
              </p>
              <p>
                The User is therefore bound to use the contents, products and services in a
                diligent, correct and lawful manner, complying with current legislation and, in
                particular, agrees to abstain from:
              </p>
              <ol>
                <li>
                  Using any of the same in any manner which is against the law or that offends
                  reasonable standards of general public morality, ethics or public order, or which
                  is in any way contrary to the instructions of the Entity.
                </li>
                <li>
                  Using any of the same in a way which harms the legitimate rights of third parties.
                </li>
                <li>
                  Accessing and/or using the Website for professional or business purposes or
                  incorporating the services and contents of the Website as its own business
                  activities.
                </li>
                <li>
                  Using contents and products and, in particular, information of any nature which is
                  obtained through the Website or the services, for advertising purposes or any form
                  of communication which has direct sales purposes or with any other commercial aim,
                  or for unsolicited messages aimed at a group of people, independent of their
                  finality, as well as abstaining from commercializing or circulating in any way any
                  such information.
                </li>
              </ol>
              <p>
                The use or application is expressly prohibited of any technical, logical or
                technological resources by virtue of which Users may benefit, directly or
                indirectly, with or without profit from the unauthorized exploitation of the
                contents and/or services of the Website.
              </p>

              <h2>4. DISCLAIMER OF WARRANTIES</h2>
              <p>
                THE WEBSITE, INCLUDING WITHOUT LIMITATION, ALL SERVICES, FEATURES, CONTENT,
                FUNCTIONS AND MATERIALS PROVIDED THROUGH THE WEBSITE, ARE PROVIDED “AS IS”, WITHOUT
                WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED. THE WEBSITE MAY CONTAIN
                INFORMATION, OPINIONS, ADVICE, WARNINGS, AND STATEMENTS PROVIDED BY DIFFERENT
                INFORMATION AND CONTENT SOURCES AS WELL AS ANY USER OF THE WEBSITE FOR WHICH THE
                ENTITY DOES NOT CONTROL THE ACCURACY OR RELIABILITY THEREOF NOR DOES IT ENDORSE ANY
                SUCH INFORMATION, OPINIONS, ADVICE, WARNINGS AND STATEMENTS. ANY INFORMATION POSTED
                ON THE WEBSITE IS INTENDED FOR GENERAL PURPOSES ONLY. THE ENTITY DOES NOT REPRESENT
                OR ENDORSE THE ACCURACY OR RELIABILITY OF ANY SUCH INFORMATION OR CONTENTS.
              </p>
              <p>
                CONSEQUENTLY, THE ENTITY DOES NOT WARRANT THE TIMELINESS, RELIABILITY, USE OR
                VERACITY OF THE INFORMATION, SEQUENCE, ACCURACY OR COMPLETENESS OF SUCH IN TO THE
                MAXIUM EXTENT PERMITTED BY APPLICABLE LAW, THE ENTITY MAKES NO REPRESENTATION NOR
                WARRANTY RELATED TO THE ACCURACY, RELIABILITY, COMPLETENESS OR TIMELINESS OF THE
                CONTENT, SERVICES, PRODUCTS, TEXT, GRAPHICS, LINKS, OR OTHER ITEMS CONTAINED WITHIN
                THE WEBSITE, OR THE RESULTS OBTAINED FROM ACCESSING AND USING THE WEBSITE AND/OR THE
                CONTENT CONTAINED HEREIN.
              </p>
              <p>
                CONSEQUENTLY, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE ENTITY IS NOT
                RESPONSIBLE FOR AND DOES NOT WARRANT:
              </p>
              <ol>
                <li>
                  THE CONTINUITY OF THE WEBSITE’S CONTENTS AND/OR THE AVAILABILITY OR ACCESSIBILITY
                  OF THE WEBSITE OR ITS TECHNICAL CONTINUITY.
                </li>
                <li>THAT CONTENTS OR PRODUCTS ARE ERROR FREE OR THAT DEFECTS WILL BE CORRECTED.</li>
                <li>
                  THE ABSENCE OF VIRUSES AND/OR OTHER HARMFUL ELEMENTS IN THE WEBSITE OR SERVER
                  WHICH HOSTS IT.
                </li>
                <li>
                  THE INVULNERABILITY OF THE WEBSITE AND/OR THE IMPREGNABILITY OF THE SECURITY
                  MEASURES ADOPTED BY THE SAME.
                </li>
                <li>THE USABILITY OR PERFORMANCE OF THE WEBSITE’S CONTENTS OR SERVICES.</li>
                <li>
                  THE LOSS OR DAMAGES CAUSED TO ANY USER OR THIRD PARTY AS A RESULT OF A PERSON
                  BREACHING THE CONDITIONS, TERMS OF USE OR INSTRUCTIONS GIVEN BY THE ENTITY ON THE
                  WEBSITE O THROUGH THE INFRINGEMENT OF THE WEBSITE’S SECURITY MEASURES.
                </li>
                <li>
                  ANY OTHER DAMAGES OF ANY NATURE WHICH MAY BE CAUSED BY REASONS PERTAINING TO THE
                  WEBSITE NOT FUNCTIONING OR TO THE DEFECTIVE FUNCTIONING OF THE WEBSITE OR ANY
                  OTHER WEBSITE OR WITH REGARD TO ANY LINKS WHICH FAIL.
                </li>
              </ol>
              <p>
                Notwithstanding the above, the Entity declares to have adopted reasonable measures,
                within its reach and within the state of technology, in order to guarantee the
                smooth functioning of the Website and to avoid the existence and transmission of
                viruses and other damaging components which could potentially harm Users.
              </p>
              <p>
                ANY COMMUNICATION OR TRANSMISSION OF CONTENTS TO THE WEBSITE WHICH INFRINGES THE
                RIGHTS OF THIRD PARTIES AND/OR THE CONTENT OF WHICH IS THREATENING, OBSCENE,
                DEFAMATORY, PORNOGRAPHIC, XENOPHOBIC, WHICH UNDERMINES PERSONAL DIGNITY OR THE
                RIGHTS OF MINORS OR WHICH IS CONTRARY TO CURRENT LEGISLATION, OR ANY CONDUCT OF THE
                USER WHICH INCITES OR CONSTITUTES A CRIMINAL OFFENCE, IS TOTALLY PROHIBITED.
              </p>
              <p>
                LIKEWISE, THE INCLUSION AND COMMUNICATION OF CONTENTS BY USERS WHICH, I) ARE FALSE
                OR INACCURATE AND WHICH MAY INDUCE ERROR OR MISLEAD OTHER USERS OR THE ENTITY’S
                PERSONNEL, II) WHICH INFRINGE THE INTELLECTUAL PROPERTY RIGHTS OF THIRD PARTIES,
                III) WHICH MAY UNDERMINE OR HARM THE REPUTATION OR IMAGE OF THE ENTITY; IV) WHICH
                MAY BE CONSIDERED AS ILLEGAL, MISLEADING OR UNFAIR ADVERTISING, OR V) WHICH CONTAIN
                VIRUSES OR ANY OTHER ELECTRONIC ELEMENT WHICH MAY HARM OR HINDER THE FUNCTIONING OF
                THE WEBSITE, THE INTERNET, IT EQUIPMENT BELONGING TO THE ENTITY OR TO THIRD PARTIES
                OR ACCESS TO THE WEBSITE BY OTHER USERS, IS TOTALLY PROHIBITED.
              </p>

              <h2>5. LIMITATION OF LIABILITY AND INDEMNIFICATION</h2>
              <p>
                TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT, INCLUDING BUT NOT
                LIMITED TO NEGLIGENCE, SHALL THE ENTITY OR ANY OF OUR AFFILIATES, BRANCHES OR ANY OF
                OUR DIRECTORS, OFFICERS, EMPLOYEES, AGENTS OR CONTENT OR SERVICE PROVIDERS BE LIABLE
                FOR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY OR PUNITIVE
                DAMAGES ARISING FROM, OR DIRECTLY OR INDIRECTLY RELATED TO THE USE OF, OR THE
                INABILITY TO USE, THE WEBSITE OR THE CONTENTS, FEATURES, MATERIALS AND FUNCTIONS
                RELATED THERETO. THE TOTAL LIABILITY OF THE ENTITY, AFFILIATES, BRANCHES, DIRECTORS,
                OFFICERS, EMPLOYEES, AGENTS OR CONTENT OR SERVICE PROVIDERS TO USERS FOR ALL
                DAMAGES, LOSSES AND CAUSES OF ACTION WHETHER IN CONTRACT OR TORT (INCLUDING BUT NOT
                LIMITED TO NEGLIGENCE OR OTHERWISE) ARISING FROM THE USE OF THE WEBSITE SHALL BE
                LIMITED TO AND NOT EXCEED THE AMOUNT, IF ANY, PAID BY THE USER TO THE ENTITY FOR USE
                OF THE WEBSITE OR PURCHASE OF PRODUCTS OR SERVICES THROUGH THE WEBSITE. USERS WAIVE
                THE RIGHT THEY MIGHT OTHERWISE HAVE TO TRIAL BY JURY AND TO CLASS AND COLLECTIVE
                ACTIONS.
              </p>
              <p>
                The User agrees to hold the Entity and any of its affiliates, branches, officers,
                directors, employees and agents harmless from any and all claims, liabilities, costs
                and expenses, including attorneys´ fees arising in any way from use of the website,
                the placement or transmission of any message, content, information, software or
                other materials through the website or for violation of the law or these terms and
                conditions contained in this Legal Notice.
              </p>

              <h2>6. CANCELLATION OF ACCESS AND USE</h2>
              <p>
                The Entity reserves the right to deny, withdraw, suspend and/or block the access to
                the Website and/or any services to those Users who fail to comply with this Legal
                Notice and/or any applicable terms or use, being able to delete their registration
                and all information and files relating to the same. TO THE MAXIMUM EXTENT PERMITTED
                BY APPLICABLE LAW, THE ENTITY SHALL NOT ASSUME ANY LIABILITY TO ANY USER FOR THE
                CANCELLATION OF ACCESS TO THE WEBSITE FOR THE CAUSE STATED IN THIS PARAGRAPH.
              </p>

              <h2>7. INTELLECTUAL PROPERTY RIGHTS</h2>
              <p>
                The Entity is the owner and/or the rights holder and/or has obtained a corresponding
                licence of the intellectual property rights and/or image rights, where necessary
                and/or subsisting, pertaining to the contents available through the Website. The
                term “contents” as used anywhere herein, extends but is not limited to the texts,
                graphic designs, drawings, codes, software, photographs, videos, sounds, indices,
                images, brands, logos, expressions, information and, in general, any other creation
                which is protected by national regulations and international treaties on
                intellectual property.
              </p>
              <p>
                The products and services offered through the Website, and the intellectual property
                rights therein, are and shall remain the exclusive property of the Entity.
              </p>
              <p>
                All intellectual property rights in and to all contents are reserved and, in
                particular, it is forbidden to modify, copy, reproduce, publicly communicate,
                transform or distribute in any way the totality or part of any contents included in
                the Website for public or commercial means unless with the prior, express and
                written authorization of the Entity or, as the case may be, from the third party
                owner or rights holder of the same. Among others, the use of any technology to
                extract and collect information and contents from the Website is forbidden.
              </p>
              <p>
                Access to and navigation through the Website will in no case be understood as a
                relinquishment, transmission, license or total or partial transfer of any rights by
                the Entity howsoever. Consequently, it is not permitted to delete, evade or
                manipulate any indicators of rights ownership (for example “copyright”, “©”,
                “trademark” or “™” indicators) or other identifying data, whether in favour of the
                Entity or any other parties, and/or any technical protection mechanisms,
                fingerprints or whichever information or identification mechanisms may be contained
                in, or otherwise pertain to, any contents.
              </p>
              <p>
                Any references to names and commercial or registered brands, logos or other
                distinctive marks, which are owned by the Entity or by others, implicitly forbid
                their use without the authorization from the Entity or from the owner or the rights
                holder. At no time, unless otherwise expressly stated, shall access or use of the
                Website and/or its contents, give the User any right whatsoever to the brands, logos
                and/or distinctive signs included in the Website, each of which is protected by Law.
              </p>

              <h2>8. LINKS</h2>
              <h3>8.1 Links from the Website to other websites</h3>
              <p>
                The Entity may offer direct or indirect links to other Internet websites which are
                outside of the Website. The presence of these links in the Website have a purely
                informative purpose only and at no time constitute an invitation to contract the
                products and/or services offered on such websites. Furthermore, no such link implies
                the existence of a commercial link or relationship with the person or Entity owning
                the website to which the link is offered. In any such case, the Entity will not be
                responsible for establishing general conditions to be taken into account in the use,
                provision or contracting of or for any such services or products and, as such, the
                Entity may not be held responsible in any way in relation with any such products or
                services in any manner howsoever.
              </p>
              <p>
                The Entity does not have the knowledge, human resources or technical means to
                control or approve the information, contents, products or services provided by or
                through other websites to which it offers a link from the Website. CONSEQUENTLY, TO
                THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE ENTITY WILL NOT TAKE ANY
                RESPONSIBILITY FOR ANY MATTERS RELATING TO SUCH THIRD PARTY WEBSITES LINKED WITH THE
                WEBSITE. SPECIFICALLY, WITHOUT LIMITATION, THE ENTITY WILL NOT BE RESPONSIBLE IN ANY
                WAY WHATSOEVER FOR THE FUNCTIONING, ACCESS, DATA, INFORMATION, FILES, QUALITY,
                PRODUCTS AND SERVICES, LINKS AND/OR CONTENT OF ANY SUCH WEBSITES.
              </p>
              <p>
                Notwithstanding the above, where the Entity becomes aware that the activity or the
                information which it links to is illegal and will lead to a crime or damage the
                rights or property of third parties, it will act promptly with diligence to delete
                or cease from using the corresponding link.
              </p>
              <p>
                Likewise, if Users become aware of the illegality of the activities carried out
                through any such third party websites, they will be under the obligation to
                communicate such matter to the Entity at the earliest reasonable opportunity such
                that the Entity may evaluate the same and act appropriately.
              </p>

              <h3>8.2 Links from other web pages to the Website</h3>
              <p>
                If any User, Entity or webpage wishes to establish a link to the Website of any
                nature, they must comply with the following conditions:
              </p>
              <ol>
                <li>
                  They will need to obtain prior, express and written authorization from the Entity.
                </li>
                <li>
                  The link will only be made to the Website’s homepage, unless otherwise stated or
                  authorized.
                </li>
                <li>
                  The link will need to be absolute and complete, i.e. it must lead the User through
                  a click to the main page and must include the whole of that page. In no case,
                  unless otherwise authorized by the Entity, will the webpage from which the link is
                  made be able to i), reproduce in any way on the Website, ii), include the Website
                  as part as its own website or as any frames from such website, or iii), be able to
                  create a browser on any of the Website pages.
                </li>
                <li>
                  On the website from which the link is established, unless with the Entity’s
                  express prior written approval, no declaration of any nature may be made to the
                  effect that the Entity has authorized the link. If the Entity providing the link
                  from its webpage to the Website wishes to include on its own webpage any brand,
                  denomination, commercial name, label, logo or any other sign which identifies the
                  Entity and/or the Website, they must obtain previous, express and written
                  authorization from the Entity.
                </li>
                <li>
                  The Entity forbids the link to the Website from all those webpages which contain
                  materials, information or contents which are illegal, degrading, obscene and in
                  general, which infringe upon morality, public order, current legislation,
                  generally accepted social rules or which harm the legitimate rights of third
                  parties.
                </li>
              </ol>

              <h2>9. PRIVACY</h2>
              <p>
                When it is required that the User registers or provides personal data (in order to
                access services, subscribe to newsletters, carry out any registration process,
                request information, acquire products, send forms, make consultations or complaints
                or solicit any contractual transaction, among others), the User will be alerted as
                to the need to provide his/her personal data.
              </p>
              <p>
                In any event, the collection and processing of personal data will be carried out in
                compliance with the requirements of the applicable data protection regulations as
                well as the Privacy Policy which forms an integral part of this Legal Notice.
              </p>

              <h2>10. DURATION AND MODIFICATION</h2>
              <p>
                The Entity reserves the right to modify any of the terms and conditions of this
                Legal Notice and/or the particular terms and conditions which may have been
                established for the use and/or contracting of the products and services provided
                through the Website, whenever it deems it appropriate due to business reasons and/or
                in order to adapt and comply with any changes in legislation and in technology,
                becoming effective since the updated publication of the same on the Website.
              </p>
              <p>
                The term of this Legal Notice coincides with the duration of its publication and
                exhibition in the Website, until such time as it is totally or partially modified.
                At such moment, the modified terms of use will become binding.
              </p>
              <p>
                The Entity may, at any time, finalize, cancel or interrupt access to the published
                content. In any such case, the User will have no right to claim compensation of any
                kind. Following any such cancellation, the prohibitions that are set out above in
                this Legal Notice regarding the use of contents will remain valid.
              </p>

              <h2>11. COMMUNICATIONS</h2>
              <p>
                For any communication between the Entity and the User, the User must contact with
                the Entity through the postal and/or email address provided on the Website.
                Communications from the Entity to the User must comply with the contact information
                provided by the User. The User therefore expressly accepts the use of the email
                address provided as a valid means for the exchange of information between the Entity
                and the User.
              </p>

              <h2>12. MISCELLANEOUS</h2>
              <p>
                The headings of the different sections herein only have an informative nature and do
                not affect, qualify or modify the interpretation of this Legal Notice.
              </p>
              <p>
                Where there is any discrepancy between the effects of this Legal Notice and the
                particular terms which may be established in relation to any specific products or
                services offered on the Website, the latter will prevail.
              </p>
              <p>
                Where any one of the provisions set forth in this Legal Notice could be considered
                as not being totally or partially binding by a Court of Law or by a recognized
                regulatory body, such nullity will not affect the other provisions contained in this
                Legal Notice nor any other provisions which have been established.
              </p>
              <p>
                Where the Entity does not exercise any of the rights contained in this Legal Notice,
                such event will not constitute a relinquishment of this right, unless expressly
                stated in writing.
              </p>

              <h2>13. GOVERNING LAW</h2>
              <p>This Legal Notice is governed by the laws of Spain.</p>

              <p>
                <strong>© 2022 SAMY ROAD, SL All rights reserved.</strong>
              </p>
            </div>
          </StyledSection>
        </section>
      </main>
      <Footer />
      <Menu show={showFullMenu} closeMenu={handleMenuAction} location={location} />
    </Layout>
  )
}

export default LegalNotice
